import React, { useEffect, useState } from 'react';
import SimpleSidebar from '../components/sidebar';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Box, Button, Center, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react';

interface ProfileCardProps {
  username: string;
}

const ProfileCard = ({ username }: ProfileCardProps) => {
  return (
    <Center py='6'>
      <Box maxW={'540px'} w={'full'} rounded={'md'} overflow={'hidden'} bg='teal.400'>
        <Box p={6} color='gray.100'>
          <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
            Welcome, {username}
          </Heading>
        </Box>
      </Box>
    </Center>
  );
};

const Home = () => {
  const auth = useAuthenticator();
  return (
    <div>
      <SimpleSidebar>
        {/* <Box h='100%'>
          <Center>Welcome, {auth.user.getUsername()}</Center>
        </Box> */}
        <ProfileCard username={auth.user.getUsername()}></ProfileCard>
      </SimpleSidebar>
    </div>
  );
};

export default Home;
