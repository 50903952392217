import { makeAutoObservable } from 'mobx';

export class FileStore {
  filePath = '';
  fileName = '';
  fileTag = '';

  constructor() {
    makeAutoObservable(this);
  }

  public setFilePath(filePath: string) {
    this.filePath = filePath;
    this.fileName = filePath.split('/').slice(-1)[0];
  }

  public getFilePath() {
    return this.filePath;
  }

  public getFileName() {
    return this.fileName;
  }

  public setFileTag(fileTag: string) {
    this.fileTag = fileTag;
  }

  public getFileTag() {
    return this.fileTag;
  }
}
