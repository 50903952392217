import { API, Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import SimpleSidebar from '../components/sidebar';

type FileDetail = {
  fileName: string;
  fileId: string;
  fileTag: string;
  state: string;
  createdAt: string;
};

const Dataset = () => {
  const [fileList, setFileList] = useState<FileDetail[]>([]);

  const downloadFile = async (fileId: string) => {
    console.log(`/files/${fileId}`);
    try {
      const ret = await API.get('labcloud-api', `/files/${fileId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        },
      });
      console.log(ret);

      const downloadUrl = ret?.['signedUrl'];
      window.open(downloadUrl);
    } catch (err) {
      console.error(err);
    }
  };

  const checkFiles = async () => {
    const ret = await API.get('labcloud-api', '/files', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    });
    const fileList = (ret['files'] as FileDetail[]).sort((a, b) => b.fileName.localeCompare(a.fileName));
    setFileList(fileList);
  };

  useEffect(() => {
    checkFiles();
  }, []);

  return (
    <div>
      <SimpleSidebar>
        <TableContainer>
          <Table variant='simple' colorScheme='blackAlpha'>
            {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
            <Thead>
              <Tr>
                <Th>File Name</Th>
                <Th>File Tag</Th>
                <Th>status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fileList.map(item => {
                return (
                  <Tr key={item.fileId}>
                    <Td>{item.fileName}</Td>
                    <Td>{item.fileTag}</Td>
                    <Td>{item.state == 'uploaded' ? 'uploaded' : 'waiting'}</Td>
                    <Td>
                      {item?.state == 'uploaded' && (
                        <Button
                          size='xs'
                          colorScheme='teal'
                          variant='solid'
                          onClick={() => {
                            downloadFile(item.fileId).then(() => console.log('ok'));
                          }}
                        >
                          Download
                        </Button>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </SimpleSidebar>
    </div>
  );
};

export default Dataset;
