import { Box, Button, Center, Flex, Input, InputGroup, InputLeftAddon, Text } from '@chakra-ui/react';
import Amplify, { API, Auth } from 'aws-amplify';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RealtimeGraph from '../components/realtime-graph';
import SimpleSidebar from '../components/sidebar';
import { useStores } from '../use-store';

type DeviceStatus = 'init' | 'starting' | 'started' | 'stopping' | 'stopped' | 'unknown';

const Viewer = observer(() => {
  const { fileStore, websocketStore } = useStores();
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>('unknown');
  const checkStatus = async () => {
    const ret = await API.get('local', '/status', {});
    const status = ret['status'];
    setDeviceStatus(status);
    if (status == 'init') {
      websocketStore.setCount(0);
      fileStore.setFilePath('');
    } else {
      const ret = await API.get('local', '/path', {});
      console.log(ret);
      fileStore.setFilePath(ret['path']);
    }
  };

  const start = async () => {
    await checkStatus();
    if (deviceStatus == 'started') {
      alert('device is already started');
      return;
    }
    await API.post('local', '/start', {});
    await checkStatus();
  };

  const stop = async () => {
    await checkStatus();
    if (deviceStatus == 'stopped') {
      alert('device is already stopped');
      return;
    }
    await API.post('local', '/stop', {});
    await checkStatus();
  };

  const reset = async () => {
    await checkStatus();
    if (deviceStatus == 'init') {
      alert('device is already reseted');
      return;
    }
    await API.post('local', '/reset', {});
    await checkStatus();
  };

  const upload = async () => {
    const fileName = fileStore.getFileName();
    if (!fileName || fileName == '') {
      alert('can not get file name');
      return;
    }

    let ret = await API.get('labcloud-api', `/files/upload-endpoint?fileName=${fileName}&fileTag=${fileStore.getFileTag()}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    });

    if (!ret?.['bucketDetail']?.['fields']) {
      console.log('no bucket detail');
      return;
    }

    const bucketDetail = ret['bucketDetail'];
    console.dir(bucketDetail);
    try {
      ret = await API.post('local', '/upload', {
        body: {
          url: 'https://s3.ap-southeast-1.amazonaws.com/541276308344-lab-bucket',
          fields: bucketDetail['fields'],
        },
      });
      alert(`${fileStore.getFileName()} is uploaded`);
      await reset();
    } catch (err) {
      alert(`${fileStore.getFileName()} fail to upload ${err}`);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log('check status');
      checkStatus();
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    console.log('device status:', deviceStatus);
  }, [deviceStatus]);

  return (
    <div>
      <SimpleSidebar>
        <Center pt='2'>
          <Flex>
            <Center pl='2'>
              <InputGroup w='xs'>
                <InputLeftAddon>Tag</InputLeftAddon>
                <Input value={fileStore.getFileTag()} onChange={e => fileStore.setFileTag(e.target.value)}></Input>
              </InputGroup>
            </Center>
            {deviceStatus == 'stopped' && (
              <Center>
                <Button
                  colorScheme='teal'
                  variant='solid'
                  size='sm'
                  ml='2'
                  onClick={() => {
                    upload();
                  }}
                >
                  upload
                </Button>
              </Center>
            )}
            <Center>
              <Button
                colorScheme='teal'
                variant='solid'
                size='sm'
                ml='2'
                onClick={() => {
                  switch (deviceStatus) {
                    case 'init':
                      start();
                      break;
                    case 'started':
                      stop();
                      break;
                    case 'stopped':
                      reset();
                      break;
                  }
                }}
              >
                {deviceStatus == 'init' && 'start'}
                {deviceStatus == 'started' && 'stop'}
                {deviceStatus == 'stopped' && 'reset'}
              </Button>
            </Center>
          </Flex>
        </Center>
        <Center pt='2'>
          <Flex>
            <Center>
              <InputGroup w='sm'>
                <InputLeftAddon>File</InputLeftAddon>
                <Input disabled={true} value={fileStore.getFileName()}></Input>
              </InputGroup>
            </Center>
            <Center pl='2'>
              <InputGroup w='3xs'>
                <InputLeftAddon>Status</InputLeftAddon>
                <Input disabled={true} value={deviceStatus}></Input>
              </InputGroup>
            </Center>
            <Center pl='2'>
              <InputGroup w='3xs'>
                <InputLeftAddon>Samples</InputLeftAddon>
                <Input disabled={true} value={websocketStore.getCount()}></Input>
              </InputGroup>
            </Center>
          </Flex>
        </Center>
        <RealtimeGraph></RealtimeGraph>
      </SimpleSidebar>
    </div>
  );
});

export default Viewer;
