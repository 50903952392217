import { createAtom, autorun } from 'mobx';

import { Buffer } from 'buffer';

type WsMsgType = {
  labels: string[];
  data: string[];
};

const decodeData = (data: string[], signal: number[][]) => {
  const muls = [2, 2, 2, 125, 125, 125, 1300, 1300, 2500];
  for (const ch in signal) {
    const buf = Buffer.from(data[ch], 'base64');
    const u8array = new Uint8Array(buf);
    const dataView = new DataView(u8array.buffer);
    const i32Len = u8array.length >> 2;
    for (let i = 0; i < i32Len; i++) {
      const val = dataView.getInt32(i * 4, true);
      signal[ch].shift();
      signal[ch].push((val * muls[ch]) / 65536);
    }
  }

  return signal;
};

export class WebsocketStore {
  atom;
  ws: WebSocket | null = null;
  channels = 9;
  samplingRate = 20;
  plotDuration = 5; /* seconds */

  t = new Array(this.samplingRate * this.plotDuration)
    .fill(0)
    .map((tt, idx) => ((idx + 1) / this.samplingRate - this.plotDuration).toFixed(1));
  signal = new Array(this.channels).fill(0).map(x => new Array(this.samplingRate * this.plotDuration).fill(0));

  count = 0;

  constructor() {
    console.log('init');
    this.atom = createAtom(
      'Websocket',
      () => this.start(),
      () => this.stop(),
    );
  }

  start() {
    console.log('start ws store');
    this.ws = new WebSocket('ws://localhost:8000/ws');
    this.ws.onopen = () => console.log('ws opened');
    this.ws.onclose = e => console.log('ws closed:', e);
    this.ws.onerror = e => console.log('ws error:', e);
    this.ws.onmessage = e => {
      this.handle(e.data);
    };
  }

  stop() {
    console.log('stop ws store');
    if (this.ws) {
      this.ws.close();
    }
  }

  handle(data: any) {
    // console.log('handle ws store');
    const msg = JSON.parse(data) as WsMsgType;
    // console.log(msg.labels);
    // console.log(msg.data);

    this.count = this.count + 1;
    // console.log('count:', this.count);

    this.signal = decodeData(
      msg.data,
      this.signal.map(arr => arr.slice()),
    );

    this.atom.reportChanged();
  }

  getSignal() {
    this.atom.reportObserved();
    return {
      time: this.t,
      signal: this.signal,
    };
  }

  setCount(count: number) {
    this.count = count;
    this.atom.reportChanged();
  }

  getCount() {
    this.atom.reportObserved();
    return this.count;
  }
}
