import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';

import { Buffer } from 'buffer';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { useStores } from '../use-store';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RealtimeGraph = observer(() => {
  const { websocketStore } = useStores();

  /* profiling every 10 s */
  useEffect(() => {
    let tmpCounter = 0;

    console.log('profiling');
    const intervalId = setInterval(() => {
      const count = websocketStore.getCount();
      console.log('counts per second:', (count - tmpCounter) / 10);
      tmpCounter = count;
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const options: (title: string) => ChartOptions<'line'> = (title: string) => {
    return {
      responsive: true,
      animation: false,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: title,
        },
      },
    };
  };

  const data: (signalIndexs: number[]) => ChartData<'line', number[], string> = (signalIndexs: number[]) => {
    const tmp = websocketStore.getSignal();

    return {
      labels: tmp?.time,
      datasets: [
        {
          label: 'X',
          data: tmp?.signal[signalIndexs[0]],
          borderColor: 'rgb(255, 100, 100)',
          // backgroundColor: 'rgba(255, 100, 100, 0.5)',
        },
        {
          label: 'Y',
          data: tmp?.signal[signalIndexs[1]],
          borderColor: 'rgb(100, 255, 100)',
          // backgroundColor: 'rgba(100, 255, 100, 0.5)',
        },
        {
          label: 'Z',
          data: tmp?.signal[signalIndexs[2]],
          borderColor: 'rgb(100, 100, 255)',
          // backgroundColor: 'rgba(255, 100, 100, 0.5)',
        },
      ],
    };
  };

  return (
    <div>
      <Line options={options('Accelerometer')} data={data([0, 1, 2])} height='50px' />
      <Line options={options('Gyrometer')} data={data([3, 4, 5])} height='50px' />
      <Line options={options('Magnetometer')} data={data([6, 7, 8])} height='50px' />
    </div>
  );
});

export default RealtimeGraph;
