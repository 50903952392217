import React from 'react';
import logo from './logo.svg';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { AmplifyProvider, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';

import awsExports from './aws-exports';
import Home from './pages/home';
import Viewer from './pages/viewer';
import Dataset from './pages/dataset';
import Logout from './pages/logout';

Amplify.configure(awsExports);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'labcloud-api',
        endpoint: 'https://83mcanxyj6.execute-api.ap-southeast-1.amazonaws.com/prod-pracha',
      },
      {
        name: 'local',
        endpoint: 'http://localhost:8000',
      },
    ],
  },
});

function App() {
  return (
    <div className='App'>
      <AmplifyProvider>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/viewer' element={<Viewer />} />
          <Route path='/dataset' element={<Dataset />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>
      </AmplifyProvider>
    </div>
  );
}

export default withAuthenticator(App);
